@import "art-sizing";
@import "hp-borders";
@import "../variables/_spacers.scss";
@import "../variables/_colors.scss";
@import "../framework/utilities/_zindex.scss";

$xsW: 16px;
$colW: 34px;
$gutterW: 32px;
$dividerColor: map-get($color, "gray-lighter");
$dividerColorBold: map-get($color, "gray-dark");
$ffSidebarW: 32px; // this happens to be equal to gutterW but doesn't have to be
$compensateForZoom: 2; // reduce by 2 pixels so there's no overflow when zooming

// When a line is present, these will be doubled
$separatorLargeChainRow: 40px;
$separatorLargeForMobileChainRow: 24px;
$separatorSmallForMobileChainRow: 16px;

@function colstopix($columns, $colWidth, $gutterWidth) {
  @return ($columns) * $colWidth + ($columns - 1) * $gutterWidth;
}

@mixin col-start($size) {
  &--c-start {
    grid-column-start: var(--c-start-#{$size});
  }
}

@mixin col-spans($size) {
  &--c-spans {
    grid-column-end: span var(--c-span-#{$size});
  }
}

@mixin row-start($size) {
  &--r-start {
    grid-row-start: var(--r-start-#{$size});
  }
}

//Todo: probably only need mobile/not mobile
@mixin row-spans($size) {
  &--r-spans {
    grid-row-end: span var(--r-span-#{$size});
  }
}

@mixin extra-rows($size) {
  grid-template-rows: repeat(var(--extra-rows-#{$size}), max-content) 1fr;
}

.card-left.card-text,
.card-right.card-text {
  word-wrap: break-word;
}

// NOTE: This was formerly in the grid-art card-art-xs (deprecated) mixins,
// but that led to redundant styles
.hpgrid-item {
  .no-wrap-text {
    .card-text {
      &.card-left {
        float: left;
      }
      &.card-right {
        float: right;
      }
    }
  }
}

@mixin grid-art($breakpointSize) {
  .hpgrid-item {
    @each $unitSize, $artSizes in $art-at-sizes {
      &[data-#{$breakpointSize}-span="#{$unitSize}"] {
        $marginW: 1 * 0.5 * $gutterW;
        @if $unitSize == 4 {
          $marginW: 1 * 0.25 * $gutterW;
          .art--left {
            margin-right: $marginW;
          }
          .art--right {
            margin-left: $marginW;
          }
        }
        // --w-{size} - for left/right-aligned art zone in ff/story-card
        // --w-{size}-t - for left/right-aligned (t)ext zone in ff/story-card
        @each $artSize, $artUnit in $artSizes {
          // NOTE: mini and tiny use hard-codes sizes, all others are grid-based
          $isMn: $artUnit == "mn";
          $isTn: $artUnit == "tn";
          $size: if(
            $isMn,
            $mnSize,
            if($isTn, $tnSize, colstopix($artUnit, $colW, $gutterW))
          );
          --w-#{$artSize}: #{$size};
          --w-#{$artSize}-t: calc(100% - #{$size + $marginW});
        }
      }
    }
  }
}

// START: For chain- and table-level CTA
.hpgrid-item.table-cta,
.chain-cta {
  .label {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .hpgrid-item.table-cta,
  .chain-cta {
    .label {
      text-align: left;
    }
  }
}
// END: For chain- and table-level CTA

.hpgrid {
  position: relative;
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: repeat(auto-fill, $colW);
  grid-auto-flow: dense;
  align-items: start;
  &-item.card {
    height: 100%; // hack for now
  }
  &.chain {
    gap: var(--table-row-gap) var(--table-col-gap);
    &:empty {
      display: none;
    }
    &:not(:has(> .table-in-grid:not(:empty))) {
      &:has(> .table-in-grid:empty) {
        display: none;
      }
    }
  }
  &.table-in-grid {
    gap: var(--feature-row-gap) var(--feature-col-gap);
  }
  &-close-the-gap {
    margin-bottom: calc(-1 * var(--feature-row-gap));
  }
}

// @param level (string) -- (table|feature) will end up as var(--#{$level}-(row|col)-gap
// @param offset (num) -- (horizontal: 0=left, 1/2=center, 1=right), (vertical: 0=top, 1/2=middle, 1=bottom)
// @param position (absolute or relative) -- how to position the divider
@mixin horizontal-divider-top(
  $level: "feature",
  $offset: 0.5,
  $position: absolute
) {
  content: "";
  position: $position;
  top: calc(-1 * $offset * var(--#{$level}-row-gap));
  left: 0;
  width: 100%;
  border-top: 1px solid $dividerColor;
}
@mixin horizontal-divider-bottom(
  $level: "feature",
  $offset: 0.5,
  $position: absolute
) {
  content: "";
  position: $position;
  bottom: calc(-1 * #{$offset} * var(--#{$level}-row-gap) - 1px);
  left: 0;
  width: 100%;
  border-top: 1px solid $dividerColor;
}
@mixin horizontal-divider-lengthener(
  $level: "feature",
  $offset: 0.5,
  $position: absolute
) {
  left: calc(-1 * $offset * var(--#{$level}-col-gap));
  width: calc(100% + var(--#{$level}-col-gap));
}
@mixin horizontal-divider-lengthener-right(
  $level: "feature",
  $offset: 0.5,
  $position: absolute
) {
  left: 0;
  width: calc(100% + 1 * #{$offset} * var(--#{$level}-col-gap));
}
@mixin horizontal-divider-lengthener-left(
  $level: "feature",
  $offset: 0.5,
  $position: absolute
) {
  left: calc(-1 * $offset * var(--#{$level}-col-gap));
  width: calc(100% + 1 * #{$offset} * var(--#{$level}-col-gap));
}
@mixin horizontal-divider-off() {
  border-top-width: 0px;
  border-bottom-width: 0px;
}
@mixin vertical-divider-off() {
  border-left-width: 0px;
}
@mixin vertical-divider-left(
  $level: "feature",
  $offset: 0.5,
  $position: absolute
) {
  content: "";
  position: $position;
  top: calc(-1 * var(--#{$level}-row-gap));
  left: calc(-1 * $offset * var(--#{$level}-col-gap));
  height: calc(100% + var(--#{$level}-row-gap));
  border-left: 1px solid $dividerColor;
}
@mixin vertical-divider-right(
  $level: "feature",
  $offset: 0.5,
  $position: absolute
) {
  content: "";
  position: $position;
  top: calc(-1 * var(--#{$level}-row-gap));
  right: calc(-1 * #{$offset} * var(--#{$level}-col-gap) - 1px);
  height: calc(100% + var(--#{$level}-row-gap));
  border-left: 1px solid $dividerColor;
}
@mixin vertical-divider-shortener(
  $level: "feature",
  $offset: 0.5,
  $position: absolute
) {
  top: 0;
  height: 100%;
}

// START: top table horizontal and vertical dividers galore
/* stylelint-disable color-named, selector-combinator-space-before */
$blue: #5193fe;
$green: #62a025;
$yellow: #ffe916;
$purple: #6814c9;
$magenta: #c402ad;
$orange: #f29f16;
$brown: #a56729;
$blue2: #29a4a5;
$green2: #1e7145;
$yellow2: #ffc40d;
$gray: #cccccc;
.show-helper-colors {
  // top strip
  &.table0 {
    box-shadow: 0 0 0 4px $purple;
  }
  // left table
  &.table1 {
    box-shadow: 0 0 0 4px $blue;
  }
  // center table
  &.table2 {
    box-shadow: 0 0 0 4px $yellow;
  }
  // right table
  &.table3 {
    box-shadow: 0 0 0 4px $magenta;
  }
  // table 4
  &.table4 {
    box-shadow: 0 0 0 4px $orange;
  }
  // table 5
  &.table5 {
    box-shadow: 0 0 0 4px $brown;
  }
  // table 6
  &.table6 {
    box-shadow: 0 0 0 4px $blue2;
  }
  // table 7
  &.table7 {
    box-shadow: 0 0 0 4px $green2;
  }
  // table 8
  &.table8 {
    box-shadow: 0 0 0 4px $yellow2;
  }
  // ad table 1
  &.tableAd1 {
    box-shadow: 0 0 0 4px $gray;
  }
  // far-right table
  &.table9 {
    box-shadow: 0 0 0 4px $green;
  }
  &.allcontent {
    box-shadow: 0 0 0 4px $purple;
  }
}

// NOTE: Using :before or :after for chains introduces complications b/c the grid gives it grid spacing
// So doing this differently than other spacing that uses :before or :after
@mixin chain-horizontal-separator($hspace, $includeline, $color) {
  @if $includeline {
    padding-bottom: calc(#{$hspace} - 1px);
    border-bottom: 1px solid $color;
  }
  margin-bottom: $hspace;
}
// Large: 80px with line
.chain.large-bottom-separator.line-bottom.hpgrid,
.chain-cta.large-bottom-separator.line-bottom {
  @include chain-horizontal-separator(
    $separatorLargeForMobileChainRow,
    true,
    $dividerColorBold
  );
}
// Large: 80px no line
.chain.large-bottom-separator.no-line-bottom.hpgrid,
.chain-cta.large-bottom-separator.no-line-bottom {
  @include chain-horizontal-separator(
    2 * $separatorLargeForMobileChainRow,
    false,
    $dividerColor
  );
}
// Small: 32px with line
.chain.small-bottom-separator.line-bottom.hpgrid,
.chain-cta.small-bottom-separator.line-bottom {
  @include chain-horizontal-separator(
    $separatorSmallForMobileChainRow,
    true,
    $dividerColor
  );
}
// Small: 32px no line
.chain.small-bottom-separator.no-line-bottom.hpgrid,
.chain-cta.small-bottom-separator.no-line-bottom {
  @include chain-horizontal-separator(
    2 * $separatorSmallForMobileChainRow,
    false,
    $dividerColor
  );
}
// X-Small: 16px no line
.chain.x-small-bottom-separator.no-line-bottom.hpgrid,
.chain-cta.x-small-bottom-separator.no-line-bottom {
  @include chain-horizontal-separator(
    $separatorSmallForMobileChainRow,
    false,
    $dividerColor
  );
}
// NOTE: NEED TO REVISIT THESE
@media only screen and (min-width: 768px) {
  // Large: 80px with line
  .chain.large-bottom-separator.line-bottom.hpgrid,
  .chain-cta.large-bottom-separator.line-bottom {
    @include chain-horizontal-separator(
      $separatorLargeChainRow,
      true,
      $dividerColorBold
    );
  }
  // Large: 80px no line
  .chain.large-bottom-separator.no-line-bottom.hpgrid,
  .chain-cta.large-bottom-separator.no-line-bottom {
    @include chain-horizontal-separator(
      2 * $separatorLargeChainRow,
      false,
      $dividerColor
    );
  }
}

@mixin dividers-bold($color) {
  .grid-horizontal-divider-bold::after {
    border-top-color: $color !important;
  }
  .grid-vertical-divider-bold::before {
    border-left-color: $color !important;
  }
}

@mixin dividers($prefix) {
  @each $level in (table, feature) {
    $selector: if(
      $level == "table",
      include-dividers-tables,
      include-dividers-features
    );

    .hpgrid.#{$selector} {
      /* Horizontal dividers top */
      // tables/features
      & > .#{$prefix}-middle,
      & > .#{$prefix}-bottom {
        &:not(.#{$prefix}-top):after {
          @include horizontal-divider-top($level);
        }
        /* Lengthen horizontal divider if pseudo-top of column */
        &.#{$prefix}-pseudo-top {
          &.#{$prefix}-center:not(.#{$prefix}-pseudo-left):not(
              .#{$prefix}-pseudo-right
            ):after {
            @include horizontal-divider-lengthener($level);
          }
          &.#{$prefix}-left:not(.#{$prefix}-right):after,
          &.#{$prefix}-pseudo-left:not(.#{$prefix}-right):after {
            @include horizontal-divider-lengthener-right($level);
          }
          &.#{$prefix}-right:not(.#{$prefix}-left):after,
          &.#{$prefix}-pseudo-right:not(.#{$prefix}-left):after {
            @include horizontal-divider-lengthener-left($level);
          }
        }
      }
      /* Vertical dividers left */
      // tables/features
      & > .#{$prefix}-center,
      & > .#{$prefix}-right {
        &:not(.#{$prefix}-left):before {
          @include vertical-divider-left($level);
        }
        /* Shorten vertical divider if top of column */
        &.#{$prefix}-top:before,
        &.#{$prefix}-pseudo-top:before {
          @include vertical-divider-shortener($level);
        }
      }
      /* Vertical dividers right */
      // tables
      & > .table-in-grid.#{$prefix}-left {
        &:not(.#{$prefix}-right):before {
          @include vertical-divider-right($level);
        }
        /* Shorten vertical divider if top of column */
        &.#{$prefix}-top:before,
        &.#{$prefix}-pseudo-top:before {
          @include vertical-divider-shortener($level);
        }
      }
      // NOTE: centered tables need divider on left (handled above using :before)
      // and right (handled here using :after). This should be safe even though
      // by convention horizontal uses :after b/c center will not need to use
      // :after for the overall table. The "chain" will have that.
      /* NOTE -- With table rowspans, this is no longer safe
      & > .table-in-grid.#{$prefix}-center {
        &:after {
          @include vertical-divider-right($level);
        }
        // Shorten vertical divider if top of column
        &.#{$prefix}-top:after,
        &.#{$prefix}-pseudo-top:after {
          @include vertical-divider-shortener($level);
        }
      }
      */
    }
    // NOTE: To assure dividers don't appear when marked not to appear
    .hpgrid.#{$selector} {
      & > .#{$prefix}-horizontal-divider-off:not(.#{$prefix}-top):after {
        @include horizontal-divider-off();
      }
      // NOTE: For now, when $prefix is table, this will never be active
      & > .#{$prefix}-vertical-divider-off:not(.#{$prefix}-left):before {
        @include vertical-divider-off();
      }
    }
  }
}
/* NOTE: Keep in sync with getPositionClasses()
 * in shared-components/chains/top-table/utilities/data-transformation.js
 */
@include dividers("grid");
@include dividers-bold($dividerColorBold);
/*
@media only screen and (min-width: 1400px) {
  @include dividers("mx");
}
@media only screen and (min-width: 1150px) and (max-width: 1399px) {
  @include dividers("lg");
}
@media only screen and (min-width: 900px) and (max-width: 1149px) {
  @include dividers("md");
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
  @include dividers("sm");
}
@media only screen and (max-width: 767px) {
  @include dividers("xs");
}
*/
// END: top table horizontal and vertical dividers galore

// START: Why aren't these in story-card.scss?
.card-top,
.card-left,
.card-right,
.card-bottom {
  position: relative;
}

.card-top:last-child,
.card-left.no-bottom,
.card-right.no-bottom,
.card-bottom:last-child {
  & > :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.card-top:first-child,
.card-left.no-bottom,
.card-right.no-bottom,
.card-bottom:first-child {
  & > :first-child {
    padding-top: 0;
    margin-top: 0;
  }
}
// END: Why aren't these in story-card.scss?

.art--right {
  z-index: 2;
  float: right;
  margin-left: $gutterW * 0.5;
}

.art--left {
  z-index: 2;
  float: left;
  margin-right: $gutterW * 0.5;
}

.hpgrid-item.admin-info {
  margin-bottom: calc(-1 * var(--feature-row-gap));
}

//would be nice to figure out how to
//move these to PB editor and inject into page.
.show-helper-values,
.spacing.show-helper-values {
  &:before {
    @extend .z-7;

    content: attr(data-admin-id) !important;
    display: block;
    position: absolute;
    top: 0;
    left: -1 * $gutterW * 0.5;
    color: map-get($color, "gray-dark");
  }

  .rowspan {
    background: white;
    display: flex;
  }
}

//move these to PB editor and inject into page.
.hide-helpers .show-helper-values,
.hide-helpers .spacing.show-helper-values {
  &:before {
    content: "" !important;
  }
  .rowspan {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  /* all multi-column breakpoints */
}

// NOTE: If presence/absence of the 'order' property changes
// at any of the breakpoints below then
// useDesktopOrdering must change correspondingly in:
//  components/layouts/utilities/grid-helpers.js
// If order varies per table/column, then this will not be sufficient
@media only screen and (min-width: 1400px) {
  /* 20-column breakpoint */
  .hpgrid {
    &-item {
      @include col-start(mx);
      @include col-spans(mx);
      @include row-start(mx);
      @include row-spans(mx);
    }
  }

  .hpgrid.chain.has-spanning-rows {
    @include extra-rows(mx);
  }

  .hpgrid.mx-dsktp-order {
    .hpgrid-item {
      order: var(--dsktp-order);
    }
  }

  .hpgrid-max-width {
    max-width: 1288px;
    &.hide-wrapper {
      max-width: calc(1288px + 16px);
    }
  }

  @include grid-art(mx);
}

@media only screen and (min-width: 1150px) and (max-width: 1399px) {
  /* 16-column breakpoint */
  .hpgrid {
    &-item {
      @include col-start(lg);
      @include col-spans(lg);
      @include row-start(lg);
      @include row-spans(lg);
    }
  }

  .hpgrid.chain.has-spanning-rows {
    @include extra-rows(lg);
  }

  .hpgrid.lg-dsktp-order {
    .hpgrid-item {
      order: var(--dsktp-order);
    }
  }

  .hpgrid-max-width {
    max-width: 1024px;
    &.hide-wrapper {
      max-width: calc(1024px + 16px);
    }
  }

  @include grid-art(lg);
}

@media only screen and (min-width: 900px) and (max-width: 1149px) {
  /* 12-column breakpoint */
  .hpgrid {
    &-item {
      @include col-start(md);
      @include col-spans(md);
      @include row-start(md);
      @include row-spans(md);
    }
  }

  .hpgrid.chain.has-spanning-rows {
    @include extra-rows(md);
  }

  .hpgrid.md-dsktp-order {
    .hpgrid-item {
      order: var(--dsktp-order);
    }
  }

  .hpgrid-max-width {
    max-width: 760px;
    &.hide-wrapper {
      max-width: calc(760px + 16px);
    }
  }

  @include grid-art(md);
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  /* 10-column breakpoint */
  .hpgrid {
    &-item {
      @include col-start(sm);
      @include col-spans(sm);
      @include row-start(sm);
      @include row-spans(sm);
    }
  }

  .hpgrid.chain.has-spanning-rows {
    @include extra-rows(sm);
  }

  .hpgrid.sm-dsktp-order {
    .hpgrid-item {
      order: var(--dsktp-order);
    }
  }

  .hpgrid-max-width {
    max-width: 628px;
    &.hide-wrapper {
      max-width: calc(628px + 16px);
    }
  }

  @include grid-art(sm);
}

@media only screen and (max-width: 767px) {
  /* 1-column breakpoint, for phones */
  .hpgrid {
    margin: 0 map-get($spacer, "sm"); //TODO: grab from spacing
    grid-template-columns: 100%;
    &-item {
      grid-column: 1 / 1;
    }
    .hpgrid {
      margin: 0;
    }
  }

  $marginW: 1 * 0.25 * $gutterW;
  .art--left {
    margin-right: $marginW;
  }
  .art--right {
    margin-left: $marginW;
  }

  .hpgrid-item[data-xs-span="1"] {
    // @include grid-art(xs);
    // --w-{size} - for left/right-aligned art zone in ff/story-card
    // --w-{size}-t - for left/right-aligned (t)ext zone in ff/story-card
    @each $artSize in $art-sizes {
      // NOTE: mini uses mini-specific size, all others use the tiny size
      $sizeXs: if($artSize == "mini", $mnSizeXs, $tnSizeXs);
      --w-#{$artSize}: #{$sizeXs};
      --w-#{$artSize}-t: calc(100% - #{$sizeXs + $marginW});
    }
  }

  .hpgrid-max-width {
    max-width: 100%;
  }

  // NOTE: Can be used to prevent mobile blowout
  .mw-mobile {
    max-width: calc(100vw - 2 * #{map-get($spacer, "sm")});
  }
}

// NOTE: For nested hidden things in the admin
.hide-wrapper .hide-wrapper {
  margin-left: -8px;
  margin-right: -8px;
}

// START: arrange labels and form
@mixin stacked-compound-label($alignItems) {
  flex-direction: column;
  align-items: $alignItems;
  overflow: hidden;
  // NOTE: Cannot use row-gap for spacing cuz package line is the :before element
  // and that should not have a row gap. The below code works, though
  // row-gap: map-get($spacer, "xs");
  & > :not(:last-child) {
    margin-bottom: map-get($spacer, "xs");
  }
}
@mixin side-by-side-compound-label() {
  flex-direction: row;
  align-items: start;
  overflow: hidden;
  & > :not(:last-child) {
    flex-grow: 1;
  }
}

.compound-label.has-form {
  display: flex;
  flex-wrap: wrap;
  form {
    width: 100%;
    text-align: left;
    @include breakpoint-not-small {
      // NOTE: width of form controlled here
      max-width: colstopix(4, $colW, $gutterW);
    }
  }
  &.center {
    @include stacked-compound-label(center);
  }
  &:not(.center) {
    @include side-by-side-compound-label();
    & > :last-child {
      margin-left: map-get($spacer, "xs");
    }
  }
  // NOTE: :before comprises the line on the package label
  &:before {
    width: 100%;
  }
}

.chain-label-stacked,
.table-label-stacked {
  .compound-label.has-form:not(.center) {
    @include stacked-compound-label(start);
    & > :last-child {
      margin-left: 0;
    }
  }
}
// END: arrange labels and form

// NOTE: For top margin on only the first chain
.multi-table-chain:not(.multi-table-chain ~ .multi-table-chain) {
  margin-top: var(--wpds-space-150);
}
