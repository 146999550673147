.font-family-georgia {
  font-family: georgia, "Times New Roman", serif;
}
.lh-0 {
  line-height: 0;
}
@media only screen and (min-width: 1024px) {
  .font-xxxs-l {
    font-size: 0.875rem;
  }
}
