.carousel-container.controls-on-the-side {
  /* START: overall */
  --button-radius: 16px;

  &.overflow-hidden {
    overflow: visible;
  }
  /* END: overall */

  /* START: items */
  .carousel-item:last-of-type {
    margin-right: 0;
  }
  /* END: items */

  /* START: arrows */
  .arrows {
    height: 100%;
    &.mb-sm {
      margin-bottom: 0;
    }
    button {
      z-index: 2;
      position: absolute;
      top: calc(50% - var(--button-radius));
      left: calc(-1 * var(--button-radius));
      margin: 0 0 0 -2px;
      &:disabled {
        opacity: 0;
        cursor: default;
      }
      &:not(:disabled) {
        color: var(--color-ui-offblack);
        background-color: var(--color-ui-white);
        border: 1px solid var(--color-ui-gray-lighter);
      }
      &:last-child {
        margin-left: calc(100%);
      }
    }
  }
  /* END: arrows */

  /* START: touch vs. non-touch devices
   *  At mobile size:
   *    1) On non-touch devices, keep the prev/nav navigation but hide dots
   *    1) On touch devices, keep the dots but hide the prev/nav navigation
   */
  .arrows {
    @include breakpoint-small {
      display: unset;
      @include touch-devices {
        display: none;
      }
    }
  }
  /* NOTE: Selectors using the data attributes coming from site components is
   * a big ugh, but there is no class name or id on the desired element.
   */
  [data-qa*="sc-carousel-dots"] {
    display: none;
    @include touch-devices {
      padding-bottom: 0;
      margin-bottom: 0;
      display: block;
    }
  }
  /* END: touch vs. non-touch devices */
}
