.switch {
  --switch-width: 50px;
  --switch-radius: 22px;
  --switch-pos: 4px;
  --switch-outline: 2px rgba(39, 94, 254, 0.3);

  appearance: none;
  width: var(--switch-width);
  border-radius: var(--switch-radius);
  height: var(--size-spacing-lg);
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  background-color: var(--gray-darkest-alpha-25);
  transition: background-color 0.3s, box-shadow 0.2s;

  &:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform 0.3s ease, opacity 0.2s;
    left: var(--switch-pos);
    top: var(--switch-pos);
    border-radius: 50%;
    width: var(--size-spacing-md);
    height: var(--size-spacing-md);
    background: var(--color-ui-white);
    transform: translateX(0);
    box-shadow: var(--shadow-lg);
  }

  &:checked {
    background-color: var(--color-ui-gray-darkest);
    &:after {
      transform: translateX(17px);
    }
  }
}
