.diversions {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 1149px) {
  .diversions {
    display: flex;
    flex-wrap: wrap;
  }
  .diversions div {
    flex: 1 0 25%;
  }
}

@media only screen and (max-width: 767px) {
  .diversions {
    display: flex;
    flex-wrap: wrap;
  }
  .diversions div {
    flex: 1 0 50%;
  }
}

@media only screen and (max-width: 899px) {
  .div-wrap {
    margin: 0 map-get($spacer, "sm");
  }
}
