@media only screen and (max-width: 767px) {
  .notification-menu {
    max-height: 80vh;
  }
}

.notification-menu {
  max-height: calc(100vh - 80px);
  overflow: auto;
  width: 100%;
  min-width: 360px;

  a:last-child div::after {
    border-bottom: none;
  }
}

.notification-menu-item {
  position: relative;
  left: 2px;
}

.notification-menu-item::after {
  display: block;
  content: " ";
  border-bottom: 1px solid #e9e9e9;
  padding-top: 20px;
  margin-right: 10px;
}

// red dot indicating new/unseen alert
.notification-menu-item.unread::before {
  content: " ";
  display: block;
  width: 6px;
  height: 6px;
  background: #ea0017;
  border-radius: 50%;
  position: absolute;
  top: 10%;
  transform: translateY(-50%);
  left: -12px;
}
