/**
* Removing some additional padding that comes
* w/ the newsletter sign-up module at sm bp
*/

div.newsletter {
  div > .chain & {
    margin-top: 0px;
  }

  span > span[data-sc-c="promolabels"] {
    padding-top: 2px !important;
  }
  &.hide-body [data-qa="sc-newsletter-signup-body"] {
    display: none;
  }
}
