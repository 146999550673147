@import "hp-breakpoints";

.masthead-newsletter-link:hover a,
.masthead-newsletter-link:hover svg {
  color: map-get($color, "blue-hover");
  fill: map-get($color, "blue-hover");
}

@media only screen and (max-width: map-get($hp-breakpoints, "md") -1) {
  .masthead-date {
    justify-content: center;
  }
}
