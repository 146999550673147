.tooltip {
  &.r:before {
    right: 14px;
  }
}

@include breakpoint-small {
  .flex-col-sm {
    flex-direction: column;
  }
  .r-10 {
    right: 10px;
  }
}

@include breakpoint-not-small {
  .w {
    &-50-ns-4 {
      width: calc(50% - 4px);
    }
  }
}
