@import "./../variables/colors";

[data-qa="audio-slot"] {
  &.center {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  > div {
    display: flex;
  }
  .minw-max {
    min-width: auto;
  }
  [data-qa="simple-duration"] {
    white-space: nowrap;
  }
  [data-qa="options-button"] {
    svg + span {
      display: none;
    }
  }
}

.af-button {
  background-color: map-get($color, "gray-darkest");
}
.af-button:hover {
  background-color: map-get($color, "gray-dark");
}
.af-button:hover svg {
  fill: map-get($color, "gray");
}

.progress-bar {
  z-index: 5;
}

.podcast-progress-point {
  box-shadow: 1px 2px map-get($color, "gray-lighter");
  z-index: 50;
}
