@font-face {
  font-family: "Postoni";
  font-weight: 700;
  font-display: swap;
  src: url("https://www.washingtonpost.com/wp-stat/assets/fonts/PostoniWide-Bold.woff2");
  unicode-range: U+A, U+20-29, U+2C-5B, U+5D, U+5F, U+61-7D, U+A0, U+A9, U+C9,
    U+E0-E3, U+E7, U+E9, U+EA, U+ED, U+F3-F5, U+FA, U+2009, U+2013, U+2014,
    U+2018, U+2019, U+201C, U+201D, U+2026;
}

@font-face {
  font-family: "Postoni";
  font-weight: 300;
  font-display: swap;
  src: url("https://www.washingtonpost.com/wp-stat/assets/fonts/PostoniWide-Regular.woff2");
}

@font-face {
  font-family: "Franklin";
  font-weight: 700;
  font-display: fallback;
  unicode-range: U+A, U+20-29, U+2C-5B, U+5D, U+5F, U+61-7D, U+A0, U+A9, U+C9,
    U+E0-E3, U+E7, U+E9, U+EA, U+ED, U+F3-F5, U+FA, U+2009, U+2013, U+2014,
    U+2018, U+2019, U+201C, U+201D, U+2026;
  src: url("https://www.washingtonpost.com/wp-stat/assets/fonts/ITC_Franklin-Bold.woff2");
}

@font-face {
  font-family: "Franklin";
  font-weight: 300;
  font-display: fallback;
  src: url("https://www.washingtonpost.com/wp-stat/assets/fonts/ITC_Franklin-Light.woff2");
  unicode-range: U+A, U+20-29, U+2C-5B, U+5D, U+5F, U+61-7D, U+A0, U+A9, U+C9,
    U+E0-E3, U+E7, U+E9, U+EA, U+ED, U+F1, U+F3-F5, U+FA, U+2009, U+2013, U+2014,
    U+2018, U+2019, U+201C, U+201D, U+2026;
}

.font {
  &--headline {
    font-family: map-get($font-family, "headline");
    line-height: map-get($line-height, "small");
  }

  &--magazine-headline {
    font-family: map-get($font-family, "magazine-headline"),
      map-get($font-family, "headline");
    line-height: map-get($line-height, "small");
  }

  &--subhead {
    font-family: map-get($font-family, "subhead");
    line-height: map-get($line-height, "medium");
  }

  &--meta-text {
    font-family: map-get($font-family, "meta-text");
    line-height: map-get($line-height, "medium");
  }

  &--body {
    font-family: map-get($font-family, "body");
    line-height: map-get($line-height, "default");
  }

  &-copy {
    @include text-sizes-sm-md-lg("xs", "sm", "sm");
  }

  @each $size, $value in $font-size {
    &-#{$size} {
      font-size: #{$value};
    }
  }

  @include breakpoint-not-small {
    &-xxxs-ns {
      font-size: map-get($font-size, "xxxs");
    }
    &-xs-ns {
      font-size: map-get($font-size, "xs");
    }
  }

  @include breakpoint-large {
    &-md-l {
      font-size: map-get($font-size, "md");
    }
  }

  @each $weight, $value in $font-weight {
    &-#{$weight} {
      font-weight: #{$value};
    }
  }
}

h1 {
  @include text-sizes-sm-md-lg("md3", "lg", "xl");
}

h2 {
  @include text-sizes-sm-md-lg("xxs", "sm", "md");
}

.lh3 {
  line-height: map-get($line-height, "large");
}

.lh-default {
  line-height: map-get($line-height, "default");
}

.lh-initial {
  line-height: initial;
}
