@media only screen and (min-width: 768px) {
  .justify-center-hpt {
    justify-content: unset !important;
  }
}
@media only screen and (min-width: 1140px) {
  .justify-center-hpt {
    justify-content: center !important;
  }
}
