@media print {
  @page {
    margin: 16px 0;
  }
  body {
    display: inline;
  }
  .hide-for-print {
    display: none;
  }
  .show-for-print {
    display: block;
  }
  p {
    page-break-inside: avoid;
  }
  .pb-md {
    padding-bottom: 10px;
  }
  a,
  a span,
  .byline,
  .display-date,
  .author-name,
  .font-copy {
    color: #000;
    font-size: 14px;
  }
}
