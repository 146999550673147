.sectionnav {
  .vertical-logo {
    max-width: 127px;
    min-width: 127px;

    @include breakpoint-not-small {
      max-width: 232px;
    }
  }
}

/* fixed header nav being too long for the tablet */
.header-nav {
  @media only screen and (max-width: 1200px) {
    .top-nav-item-7,
    .top-nav-item-6,
    .top-nav-item-5,
    .top-nav-item-4 {
      display: none;
    }
  }

  @media only screen and (max-width: 1025px) {
    .top-nav-item-3 {
      display: none;
    }
  }
}

// NOTE: Handling z-index collision with .art--right/.art--left
#nav-item.tooltip {
  z-index: 7;
}
