/*
 * Overriding some SC styles
 * to match HP designs.
*/

footer {
  .grid {
    padding: 0;
  }

  .b.bt {
    border-color: map-get($color, "gray-lighter");
  }

  @media only screen and (max-width: 767px) {
    margin: 0 16px;
  }
}
