@import "./base";
@import "./framework/utilities/text/font";
@import "./fronts/index";

// https://arcpublishing.atlassian.net/browse/WFO-5563
wp-ad iframe[width="3"] {
  width: 100%;
}

// TODO: remove this once elex is off of fusion
.election-poster::before {
  content: "";
  display: inline-block;
  background: url("/pf/svgs/icon_election.svg") no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  vertical-align: -5px;
}

.grecaptcha-badge {
  visibility: hidden;
}

#main-content [role="tablist"] {
  [role="tab"]::after {
    border-bottom-width: 4px;
  }
  [aria-selected="false"]::after {
    border-bottom-width: 0;
  }
}

nav.border-hide {
  border-bottom-width: 0px;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

#main-content {
  background-color: var(--wpds-colors-background);
  position: sticky;
  padding-bottom: 1px;
  padding-top: 1px;
}
