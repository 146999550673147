/*
  Z-INDEX
  Base
    z = z-index
  Modifiers
    -0 = literal value 0
    -1 = literal value 1
    -2 = literal value 2
    -3 = literal value 3
  Tips on extending:
    To extend, just change the range of the for loop
    Tachyons also uses flags -999, -9999, -max, -inherit, -initial, and -unset
*/
/* provide z-index classes,  */
.z {
  @for $i from 0 through 7 {
    &-#{$i} {
      z-index: #{$i};
    }
  }
}
