$playerSize: (
  "min": 200px,
  "exp": 428px
);

.live-bar {
  width: 100%;
}

.video-wrapper {
  width: 100%;
}

.live-player-wrapper {
  min-width: map-get($playerSize, "min");
  max-width: 100%;
}

.live-player-wrapper.expanded .video-wrapper {
  max-width: 100%;
}

.live-player-wrapper.expanded {
  width: 100%;
  margin: 0;
}

.live-playlist-wrapper {
  display: flex;
}

.headline-divider {
  border-right: 1px solid var(--wpds-colors-gray80-static);
  padding-right: map-get($spacer, "md");
}

.third-item-padding {
  padding-left: map-get($spacer, "md");
}

.live-playlist-wrapper.multiple {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: baseline;
}

.alert-url {
  &:hover {
    color: var(--wpds-colors-gray700-static);
  }
}

.live-wrapper .close-video-button {
  display: none;
}

.live-text-wrapper .close-video-button {
  display: block;
}

.live-wrapper-contents {
  flex-direction: column-reverse;
  width: 100%;
}

.live-wrapper {
  justify-content: normal;
}

.banner-ml {
  margin-left: map-get($spacer, "sm");
}

.live-playlist-wrapper {
  justify-content: normal;
}

.live-playlist-container {
  padding-left: map-get($spacer, "sm");
  padding-right: map-get($spacer, "sm");
}

@media only screen and (max-width: 767px) {
  .third-item-padding {
    padding-left: map-get($spacer, "sm");
  }
  .headline-divider {
    padding-right: map-get($spacer, "sm");
  }

  .live-bar,
  .video-wrapper {
    width: 100%;
  }

  .live-wrapper-child {
    flex-direction: column-reverse;
  }

  .live-playlist-wrapper.multiple {
    max-width: 1288px;
    width: 100%;
  }

  .live-headline {
    font-size: 1rem;
    max-width: 100%;
  }

  .live-headline {
    &.expanded {
      width: auto;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .third-item-padding {
    padding-left: map-get($spacer, "sm");
  }
  .headline-divider {
    padding-right: map-get($spacer, "sm");
  }

  .live-wrapper {
    justify-content: center;
    border-bottom: 1px solid var(--wpds-colors-gray80-static);
  }

  .live-bar {
    width: 100%;
  }

  .live-player-wrapper .video-wrapper {
    width: map-get($playerSize, "min");
  }

  .live-player-wrapper.expanded .video-wrapper {
    width: map-get($playerSize, "exp");
  }

  .live-wrapper-contents {
    flex-direction: row;
  }

  .live-wrapper .close-video-button {
    display: block;
  }

  .live-text-wrapper .close-video-button {
    display: none;
  }

  .live-wrapper-child {
    width: 100%;
  }
  .live-text-wrapper.expanded {
    width: 50%;
  }

  .live-headline.multiple {
    margin-left: 10px;
  }

  .live-headline {
    font-size: 1rem;
  }

  .live-playlist-wrapper img {
    display: none;
  }

  .live-player-wrapper.expanded {
    width: 100%;
    margin: unset;
  }
}

// medium to large(sideways tablet to laptop)
@media only screen and (min-width: 900px) and (max-width: 1149px) {
  .third-item-padding {
    padding-left: map-get($spacer, "sm");
  }
  .headline-divider {
    padding-right: map-get($spacer, "sm");
  }

  .live-wrapper {
    border-bottom: 1px solid var(--wpds-colors-gray80-static);
    justify-content: center;
  }

  .live-bar {
    width: 100%;
  }

  .live-player-wrapper .video-wrapper {
    width: map-get($playerSize, "min");
  }

  .live-player-wrapper.expanded .video-wrapper {
    width: map-get($playerSize, "exp");
  }

  .live-wrapper-contents {
    flex-direction: row;
  }

  .live-wrapper .close-video-button {
    display: block;
  }

  .live-text-wrapper .close-video-button {
    display: none;
  }
  .live-text-wrapper {
    margin-right: auto;
    width: 100%;
  }

  .live-text-wrapper {
    margin-right: auto;
    width: 100%;
  }

  .banner-ml {
    margin-left: map-get($spacer, "sm");
  }

  .live-player-wrapper.expanded {
    width: 100%;
    margin: unset;
  }
}

@media only screen and (min-width: 1150px) and (max-width: 1399px) {
  .live-playlist-container {
    padding-left: map-get($spacer, "md");
    padding-right: map-get($spacer, "md");
  }
  .live-playlist-wrapper {
    justify-content: center;
  }

  .banner-ml {
    margin-left: auto;
  }

  .live-wrapper {
    justify-content: center;
    border-bottom: 1px solid var(--wpds-colors-gray80-static);
  }

  .live-bar {
    width: 100%;
  }

  .live-player-wrapper .video-wrapper {
    width: map-get($playerSize, "min");
  }

  .live-player-wrapper.expanded .video-wrapper {
    width: map-get($playerSize, "exp");
  }

  .live-wrapper-contents {
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }

  .live-wrapper .close-video-button {
    display: block;
  }

  .live-text-wrapper .close-video-button {
    display: none;
  }

  .live-text-wrapper {
    margin-right: auto;
    width: 100%;
  }
  .banner-max-width {
    max-width: 1024px;
  }

  .banner-content-wrapper {
    justify-content: center;
  }
}

@media only screen and (min-width: 1400px) {
  .live-playlist-container {
    padding-left: map-get($spacer, "md");
    padding-right: map-get($spacer, "md");
  }

  .live-playlist-wrapper {
    justify-content: center;
  }

  .banner-ml {
    margin-left: auto;
  }

  .live-wrapper {
    justify-content: center;
    border-bottom: 1px solid var(--wpds-colors-gray80-static);
  }

  .live-bar {
    width: 100%;
  }

  .live-player-wrapper .video-wrapper {
    width: map-get($playerSize, "min");
  }

  .live-player-wrapper.expanded .video-wrapper {
    width: map-get($playerSize, "exp");
  }

  .live-wrapper-contents {
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }

  .live-wrapper .close-video-button {
    display: block;
  }

  .live-text-wrapper .close-video-button {
    display: none;
  }
  .live-text-wrapper {
    margin-right: auto;
    width: 100%;
  }

  .banner-max-width {
    max-width: 1288px;
  }

  .banner-content-wrapper {
    justify-content: center;
  }
}
