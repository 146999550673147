.spacing:before,
.spacing:after {
  content: "";
  display: grid;
}

.brdr-t:before,
.brdr-b:after {
  background: map-get($color, "gray-light");
  height: 1px;
}
.brdr-t.brdr-t-thick:before,
.brdr-b.brdr-b-thick:after {
  background: map-get($color, "offblack");
  height: 3px;
}

.brdr-t-75:before,
.brdr-b-75:after {
  width: 75%;
  margin-left: 12.5%;
}
.brdr-t-50:before,
.brdr-b-50:after {
  width: 50%;
  margin-left: 25%;
}
.brdr-t-25:before,
.brdr-b-25:after {
  width: 25%;
  margin-left: 37.5%;
}

.brdr-t.hairs-breadth-t:before,
.brdr-b.hairs-breadth-b:after {
  margin-top: calc(8px / 2);
  margin-bottom: calc(8px / 2);
}
.brdr-t.cramped-t:before,
.brdr-b.cramped-b:after {
  margin-top: calc(16px / 2);
  margin-bottom: calc(16px / 2);
}
.brdr-t.tight-t:before,
.brdr-b.tight-b:after {
  margin-top: calc(24px / 2);
  margin-bottom: calc(24px / 2);
}
.brdr-t.compact-t:before,
.brdr-b.compact-b:after {
  margin-top: calc(32px / 2);
  margin-bottom: calc(32px / 2);
}
.brdr-t.normal-t:before,
.brdr-b.normal-b:after {
  margin-top: calc(40px / 2);
  margin-bottom: calc(40px / 2);
}
.brdr-t.tall-t:before,
.brdr-b.tall-b:after {
  margin-top: calc(80px / 2);
  margin-bottom: calc(80px / 2);
}
.brdr-t.giant-t:before,
.brdr-b.giant-b:after {
  margin-top: calc(120px / 2);
  margin-bottom: calc(120px / 2);
}
.brdr-t.towering-t:before,
.brdr-b.towering-b:after {
  margin-top: calc(160px / 2);
  margin-bottom: calc(160px / 2);
}
.brdr-t.shift-up-t:before {
  margin-top: -8px;
  margin-bottom: 8px;
}

.hairs-breadth-t:before,
.hairs-breadth-b:after {
  margin-top: 0;
  margin-bottom: 0;
}

.cramped-t:before,
.cramped-b:after {
  margin-top: calc(4px / 2);
  margin-bottom: calc(4px / 2);
}
.tight-t:before,
.tight-b:after {
  margin-top: calc(8px / 2);
  margin-bottom: calc(8px / 2);
}
.compact-t:before,
.compact-b:after {
  margin-top: calc(16px / 2);
  margin-bottom: calc(16px / 2);
}
.normal-t:before,
.normal-b:after {
  margin-top: calc(24px / 2);
  margin-bottom: calc(24px / 2);
}
.tall-t:before,
.tall-b:after {
  margin-top: calc(32px / 2);
  margin-bottom: calc(32px / 2);
}
.giant-t:before,
.giant-b:after {
  margin-top: calc(40px / 2);
  margin-bottom: calc(40px / 2);
}
.towering-t:before,
.towering-b:after {
  margin-top: calc(80px / 2);
  margin-bottom: calc(80px / 2);
}

.shift-up-t:before {
  margin-top: -8px;
  margin-bottom: 0;
}
