/* NOTE: selectors using the data attributes coming from site components is
 * a big ugh, but there is no altertnative for the cases below :(
 */
.slideshow-container {
  // NOTE: Make the fade work when going backward through the slideshow
  .hover-parent > .o-100:not(.o-0) {
    z-index: 1;
  }
  .image-wrapper {
    &:before {
      content: " ";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }
  > figure {
    // NOTE: Transfrom sc-imposed mb-xs to proper values
    > div.mb-xs:first-child:not(:last-child) {
      margin-bottom: map-get($spacer, "xxs");
    }
    > div.mb-xs:last-child {
      margin-bottom: 0;
    }
    // NOTE: Turn off bottom padding/margin on caption
    > div:not(:first-of-type) {
      .font--meta-text {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
  &.use-same-caption {
    > figure {
      > div:not(:first-of-type).o-0 {
        opacity: 100;
      }
    }
  }
  // NOTE: To allow click on image/overlay link
  [data-testid*="slideshow-controls"] {
    pointer-events: none;
    // NOTE: To allow click on prev/play/next buttons
    > div:first-child {
      pointer-events: auto;
      &.mb-xs {
        margin-bottom: 12px;
      }
    }
  }
  &.dots-touch:not(.is-touch-device) {
    [data-qa*="pagination-dots"] {
      display: none;
    }
  }
  &.unknown-touchiness [data-testid*="slideshow-controls"],
  &.is-touch-device [data-testid*="slideshow-controls"] {
    display: none;
  }
  // NOTE: this is a selector that finds the pagination dots across platforms
  > div:not([role="region"]):last-child {
    margin-top: map-get($spacer, "sm");
    margin-bottom: map-get($spacer, "xs");
  }

  // START: For admin toolbar
  // NOTE: Feels like a hack. But this is to make sure that the slideshow contols
  // and the toolbar are both mutually and independently accessible
  &.is-admin > :first-child {
    z-index: 3;
  }
  // END: For admin toolbar

  // START: tiny-mode
  &.tiny-mode [data-testid*="slideshow-controls"] {
    > div:first-child {
      &.mb-xs {
        margin-bottom: 0px;
      }
      button {
        margin-right: 2px;
        margin-bottom: 2px;
        padding: 0;
      }
    }
  }
  // END: tiny-mode
}

.card-top:last-child,
.card-left.no-bottom,
.card-right.no-bottom,
.card-bottom:last-child {
  // NOTE: this is a selector that finds the pagination dots across platforms
  .slideshow-container:last-child > div:not([role="region"]):last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
