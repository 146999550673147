.lc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin line-clamp($max-lines) {
  .lc-#{$max-lines} {
    -webkit-line-clamp: $max-lines;
  }
}

// NOTE: Only need lc-3 for now. Extend as necessary
@for $i from 3 through 4 {
  @include line-clamp($i);
}
