// Font sizes for different breakpoints
@mixin text-sizes-sm-md-lg($size-sm, $size-md, $size-lg) {
  font-size: map-get($font-size, $size-sm);

  @include breakpoint-medium {
    font-size: map-get($font-size, $size-md);
  }

  @include breakpoint-large {
    font-size: map-get($font-size, $size-lg);
  }
}

/*
 * Spacer Dot
 * Creates a vertically-aligned circular pseudo-element
 *
 * @param String $size - diameter in pixels
 * @param String $size - color variable defined in _colors.scss
*/
@mixin spacer-dot($size, $color) {
  &:before {
    border-radius: 50%;
    height: $size;
    width: $size;
    background-color: $color;
    vertical-align: middle;
    display: inline-block;
    content: "";
    margin-left: calc(#{$size} * 2);
    margin-right: calc(#{$size} * 2);
  }
}

/*
 * Background Color
 * Creates a class ".bg-#{color-name}" and sets the background color to the value
 * NOTE: Use only inside a .bg selector block
 *
 * @param String $color-name - color variable defined in _colors.scss
*/
//
@mixin bg-color($color-name) {
  &-#{$color-name} {
    background-color: map-get($color, $color-name);
  }
}

/*
 * Text Color
 * Creates a class ".#{color-name}" and sets the color to the value
 *
 * @param String $color-name - color variable defined in _colors.scss
*/
@mixin text-color($color-name) {
  &.#{$color-name} {
    color: map-get($color, $color-name);
  }
}

/*
 * Fill Color
 * Creates a class ".fill-#{color-name}" and sets the fill color to the value
 *
 * @param String $color-name - color variable defined in _colors.scss
*/
@mixin fill-color($color-name) {
  &.fill-#{$color-name} {
    fill: map-get($color, $color-name);
  }
}

/*
 * Text Hover Color
 * Creates a class ".hover-${color-name}" and sets the hover color to the value
 *
 * @param String $color-name - color variable defined in _colors.scss
*/
@mixin text-color-hover($color-name) {
  &.hover-#{$color-name} {
    &:hover {
      color: map-get($color, $color-name);
    }
  }
}

/*
 * Fill Hover Color
 * Creates a class ".hover-fill-${color-name}" and sets the hover fill color to the value
 *
 * @param String $color-name - color variable defined in _colors.scss
*/
@mixin fill-color-hover($color-name) {
  &.hover-fill-#{$color-name} {
    &:hover {
      fill: map-get($color, $color-name);
    }
  }
}

/*
 * Media Query: Breakpoint - "Small"
 * Covers all viewports 767px and smaller
 *
*/
@mixin breakpoint-small {
  @media only screen and (max-width: map-get($breakpoint,"sm")) {
    @content;
  }
}

/*
 * Media Query: Breakpoint - "Not Small"
 * Covers all viewports 768px and wider
 *
*/
@mixin breakpoint-not-small {
  @media only screen and (min-width: map-get($breakpoint,"sm") + 1) {
    @content;
  }
}

/*
 * Media Query: Breakpoint - Medium
 * Covers all viewports 768px - 1023px wide
 *
*/
@mixin breakpoint-medium {
  @media only screen and (min-width: map-get($breakpoint,"sm") + 1) and (max-width: map-get($breakpoint,"md")) {
    @content;
  }
}

/*
 * Media Query: Breakpoint - Large
 * Covers all viewports 1024px and wider
 *
*/
@mixin breakpoint-large {
  @media only screen and (min-width: map-get($breakpoint,"md") + 1) {
    @content;
  }
}

/*
 * Media Query: Breakpoint - Not Large
 * Covers all viewports 1023px and under
 *
*/
@mixin breakpoint-not-large {
  @media only screen and (max-width: map-get($breakpoint,"md")) {
    @content;
  }
}

/*
 * Media Query: No-Hover
 * Targets devices whose primary input mechanisms CANNOT hover (eg. touchscreen devices)
 *
*/
@mixin touch-devices {
  @media (hover: none) {
    @content;
  }
}

/*
 * Media Query: Hover
 * Targets devices whose primary input mechanisms CAN hover (eg. desktops)
 *
*/
@mixin hover-devices {
  @media (hover: hover) {
    @content;
  }
}
