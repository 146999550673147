:root {
  font-size: 16px;
}
body {
  font-family: var(--font-family-franklin);
}

body,
figure,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1rem;
}

button {
  display: inline-block;
  background-color: inherit;
  border-color: inherit;
  border-style: inherit;
  padding: inherit;
  font: inherit;
  color: inherit;
  text-transform: inherit;
}
button:focus {
  outline: none;
}
figure {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}
blockquote {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}
a {
  text-decoration: none;
  color: var(--link-color);
}

/* tablet breakpoint */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
}

/* desktop breakpoint */
@media only screen and (min-width: 1024px) {
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}

b,
strong {
  font-weight: bold;
}
