// NOTE: $sidebarW happens to equal $gridGap. It does not have to
$sidebarW: 32px;

$gridCol: 34px;
$gridGap: 32px;
// NOTE: Should match values in: ~/shared-components/story-card/_children/Image.helpers.js
$maxSquareArtWidth: 8 * $gridCol + 7 * $gridGap;
$maxVerticalArtWidth: 6 * $gridCol + 5 * $gridGap;

.card,
.chain {
  a[href]:not(.art-link):not(.label-link):not(.footnote-link) {
    color: inherit;

    &:hover:not(.hover-inherit) {
      color: var(--color-ui-gray-dark);
    }
    &:hover:not(.hover-inherit) svg:not(.hover-inherit) {
      fill: var(--color-ui-gray-dark);
    }
  }
  .label-inline:hover a {
    &.hover-blue {
      color: var(--color-brand-blue-normal);
    }
    &.hover-gray-dark {
      color: var(--color-ui-gray-dark);
    }
    &.hover-red-dark {
      color: var(--color-ui-red-dark);
    }
    &.hover-red-dark {
      color: var(--color-ui-red-dark);
    }
  }
  br {
    content: "";
    &:before {
      content: " ";
    }
    &:last-child {
      display: none;
    }
  }
  .custom-html br,
  .live-graphic br {
    content: unset;
    &:before {
      content: unset;
    }
    &:last-child {
      display: unset;
    }
  }
}

.card-sidebar {
  width: $sidebarW;
  & + .has-sidebar {
    width: calc(100% - #{$sidebarW});
  }
}
// START: Art overlay
.ao-btn {
  background-color: rgba(0, 0, 0, 0.8);
  &.bg-red {
    background-color: rgba(234, 0, 23, 0.9);
  }
  &.bold {
    > span.dib {
      font-size: 14px;
    }
  }
  &.ma-xs {
    margin: 12px;
  }
  &.pa-sm {
    padding: 8px 18px 10px 15px;
  }
  &.lh-sm {
    line-height: 20px;
  }
}
.art-link:hover .ao-btn {
  background-color: rgba(0, 0, 0, 1);
  &.bg-red {
    background-color: rgba(234, 0, 23, 1);
  }
}
.ao-btn svg {
  position: relative;
  top: 0.1825em;
  &.prefix {
    margin-right: 0.5em;
  }
  &.suffix {
    margin-left: 0.5em;
  }
}
// END: Art overlay

// START: Very useful for headlines
.lh-fronts-tiny {
  line-height: 1.3;
}

.lh-fronts-sm {
  line-height: 1.2;
}

/* name, size, not-small-size, text-transform */
$fronts-font-sizes: (
  /*      mobile     desktop          */ (blurb, 1rem, 1rem, inherit),
  (deck, 1rem, 1.5rem, inherit),
  (related-links, 1rem, 0.875rem, inherit),
  (tiny, 1rem, 1rem, inherit),
  (xs, 1.25rem, 1.125rem, inherit),
  (sm, 1.25rem, 1.25rem, inherit),
  (md, 1.25rem, 1.5rem, inherit),
  (standard, 1.625rem, 1.625rem, inherit),
  (lg, 1.625rem, 2rem, inherit),
  (xl, 1.625em, 2.5rem, inherit),
  (huge, 2.5rem, 3.5rem, inherit),
  (massive, 2.5rem, 4.5rem, inherit),
  (colossal, 2.5rem, 5.875rem, inherit),
  (colossal-all-caps, 3.5rem, 5.875rem, uppercase),
  (jumbo, 2.5rem, 7.1875rem, inherit),
  (jumbo-all-caps, 3.5rem, 7.1875rem, uppercase),
  (gargantuan, 2.5rem, 8.8125rem, inherit),
  (gargantuan-all-caps, 3.5rem, 8.8125rem, uppercase)
);

@mixin font-size($name, $size, $text-transform) {
  .font-size- {
    &#{$name} {
      font-size: $size;
      text-transform: $text-transform;
    }
  }
}

@each $name, $size, $not-small-size, $text-transform in $fronts-font-sizes {
  @include font-size($name, $size, $text-transform);
}

@include breakpoint-not-small() {
  @each $name, $size, $not-small-size, $text-transform in $fronts-font-sizes {
    @include font-size($name, $not-small-size, $text-transform);
  }
}
// END: Very useful for headlines

// START: For labels
/* A guide to the perplexed for labels.
/* NOTE: As of Apr 2022 label revision, rules are not as perplexing
package / package-nested
===
package - Use the_label_line mixin all the time
package-nested - Never Use the_label_line mixin
8px (desktop and mobile) of spacing below the_label_line
16px text size (desktop/mobile)
16px margin bottom (desktop and mobile)
*/
// start: label line
@mixin the_label_line {
  position: relative;
  background-color: var(--color-ui-black);
  height: 1px;
  display: block;
  top: 0;
  margin-bottom: 0.375rem; // due to line-heights (?), this looks better than 0.5rem
  content: "";
}
.label-package:before {
  @include the_label_line();
}
// end: label line

@include breakpoint-small {
  .label-package:before,
  .label-package-nested:before {
    margin-bottom: 0.5rem;
  }
  /* adjust the size of these labels in mobile view */
  .label > .font-xxxxs.italic,
  .label > a > .font-xxxxs.italic {
    font-size: 0.875rem;
  }
}

@mixin underline-color($color-name) {
  text-decoration: underline;
  text-decoration-color: map-get($color, $color-name);
  text-decoration-thickness: 1.25px;
  text-underline-offset: 1px;
}

.label {
  .letter-spacing {
    letter-spacing: 0.1px;
  }
  .is-opinion {
    @include underline-color(spark);
  }
  a.is-opinion:hover {
    @include underline-color(gray-dark);
  }
}
// NOTE: Ideally, these would be part of site-components
a:hover,
.label-inline:hover a {
  .hover-fill-blue {
    fill: var(--color-brand-blue-normal);
  }
  .hover-fill-gray-dark {
    fill: var(--color-ui-gray-dark);
  }
  .hover-fill-gray {
    fill: var(--color-ui-gray-base);
  }
  .hover-bg-gray-dark {
    background-color: var(--color-ui-gray-dark);
  }
  .hover-fill-red-dark {
    fill: var(--color-ui-red-dark);
  }
  .hover-bg-red-dark {
    background-color: var(--color-ui-red-dark);
  }
}
.brad-15 {
  border-radius: 15px;
}
.brad-20 {
  border-radius: 20px;
}
// END: For labels

.blt {
  --spacing: 1em;
  --delim: "\25C6";
  &.blt-inbetween {
    --spacing: 0.5em;
  }

  &.blt-tight {
    --spacing: 0.6875em;
    line-height: 1.125em;
  }

  &:before {
    content: var(--delim);
    font-size: 0.875em;
    font-style: normal;
  }

  &.blt-inbetween {
    &:before {
      position: relative;
      bottom: 0.0625em;
      line-height: 1em;
    }

    &.blt-dot:before,
    &.blt-dot-large:before {
      vertical-align: middle;
    }
  }

  @include breakpoint-small {
    --delim: "\2B25";
    &.blt-outside.blt-diamond:before {
      font-size: 0.75em;
      top: 0.125em;
    }
    &.blt-inbetween.blt-diamond:before {
      font-size: 0.75em;
      bottom: 0.125em;
      vertical-align: middle;
    }
  }

  &.blt-dot,
  &.blt-dot-large {
    --delim: "\2022";
  }

  &.blt-dot-large:before {
    font-size: 1.125em;
  }

  &.blt-outside {
    margin-left: var(--spacing);

    &:before {
      position: absolute;
      left: calc(-1 * var(--spacing));
    }

    &.blt-diamond:before {
      left: calc(-1.25 * var(--spacing));
    }
  }

  &.blt-inbetween {
    &:before {
      margin: 0 var(--spacing);
    }
  }

  &.blt-gray:before {
    color: var(--gray);
  }

  &.blt-gray-dark:before {
    color: var(--gray-dark);
  }

  &.blt-red:before {
    color: map-get($color, "red");
    // color: var(--red);
  }

  &.blt-connected:after {
    background-color: var(--gray);
    content: " ";
    width: 1px;
    height: auto;
    position: absolute;
    left: calc(-1 * var(--spacing) + 1px);
    top: 1em;
    bottom: 0;
  }

  &.blt-connected:last-child:after {
    width: 0;
  }
}

.center .blt:not(.left),
.blt.center {
  margin-left: 0;

  &.blt-outside {
    &:before {
      padding-right: calc(0.25 * var(--spacing));
      position: relative;
      left: auto;
      vertical-align: top;
    }
    &.blt-dot,
    &.blt-dot-large {
      &:before {
        padding-right: calc(0.5 * var(--spacing));
      }
    }
  }
}

.dot-xxs-gray-dark {
  &:before {
    margin-bottom: map-get($spacer, "xxs");
  }
}

/* NOTE: Without adding an extra DOM node or resorting to !important,
 * this is one effective way of escaping the .list-unstyled tachyon that is
 * forcing padding-bottom to 0
 */
.list-none.pb-xs {
  padding-bottom: 8px;
}

[contenteditable="true"],
[data-editable-property="true"] {
  ~ div:empty {
    display: none;
  }
}

.art-slot {
  &.custom-html {
    overflow: hidden;

    img,
    iframe {
      max-width: 100%;
    }
  }
}

.is-square-art {
  max-width: #{$maxSquareArtWidth};
  margin-left: auto;
  margin-right: auto;
}

.is-vertical-art {
  max-width: #{$maxVerticalArtWidth};
  margin-left: auto;
  margin-right: auto;
}

/* NOTE: For unassigned feeds in the admin */
.unassigned-feed [data-feature-name]:not(:first-of-type) {
  display: none;
}

// START: valign-center
.valign-center {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  & > .art--right,
  & > .art--left {
    float: none;
  }
}
// END: valign-center

// START: compensate for video
.card {
  .video .flex-feature {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
// END: compenstate for video
