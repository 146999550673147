@import "hp-breakpoints";

// START: show from mx DOWN to some other breakpoint
// show mx, i.e. hide lg, md, sm, xs
@media only screen and (max-width: map-get($hp-breakpoints,"mx") - 1) {
  .dn-hp-lg-to-xs {
    display: none;
  }
}

// show mx, lg, i.e. hide md, sm, xs
@media only screen and (max-width: map-get($hp-breakpoints,"lg") - 1) {
  .dn-hp-md-to-xs {
    display: none;
  }
}

// show mx, lg, md, i.e. hide sm, xs
@media only screen and (max-width: map-get($hp-breakpoints,"md") - 1) {
  .dn-hp-sm-to-xs {
    display: none;
  }
}

// show mx, lg, md, sm, i.e. hide xs
@media only screen and (max-width: map-get($hp-breakpoints,"sm") - 1) {
  .dn-hp-xs {
    display: none;
  }
}
// END: show from mx DOWN to some other breakpoint

// START: show from xs UP to some other breakpoint
// show xs, sm, md, lg, i.e. hide mx
@media only screen and (min-width: map-get($hp-breakpoints,"mx")) {
  .dn-hp-mx {
    display: none;
  }
}

// show xs, sm, md, i.e. hide lg, mx
@media only screen and (min-width: map-get($hp-breakpoints,"lg")) {
  .dn-hp-lg-to-mx {
    display: none;
  }
}

// show xs, sm, i.e. hide md, lg, mx
@media only screen and (min-width: map-get($hp-breakpoints,"md")) {
  .dn-hp-md-to-mx {
    display: none;
  }
}

// show xs, i.e. hide sm, md, lg, mx
@media only screen and (min-width: map-get($hp-breakpoints,"sm")) {
  .dn-hp-sm-to-mx {
    display: none;
  }
}
// END: show from xs UP to some other breakpoint

// START: per bp hide not covered by the above
// hide lg
@media only screen and (min-width: map-get($hp-breakpoints,"lg")) and (max-width: (map-get($hp-breakpoints,"mx") - 1)) {
  .dn-hp-lg {
    display: none;
  }
}
// hide md
@media only screen and (min-width: map-get($hp-breakpoints,"md")) and (max-width: (map-get($hp-breakpoints,"lg") - 1)) {
  .dn-hp-md {
    display: none;
  }
}
// hide sm
@media only screen and (min-width: map-get($hp-breakpoints,"sm")) and (max-width: (map-get($hp-breakpoints,"md") - 1)) {
  .dn-hp-sm {
    display: none;
  }
}
// END: per bp hide not covered by the above
